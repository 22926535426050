import React from 'react';

const NeuralNetworkRenderer = ({ nodes, connections, dimensions }) => {
  return (
    <svg width={dimensions.width} height={dimensions.height}>
      {connections.map((connection, index) => (
        <line
          key={index}
          x1={nodes[connection.from]?.x}
          y1={nodes[connection.from]?.y}
          x2={nodes[connection.to]?.x}
          y2={nodes[connection.to]?.y}
          stroke="rgba(211, 211, 211, 0.5)" // Light gray for connections
          strokeWidth="1"
        />
      ))}
      {nodes.map((node, index) => (
        <circle
          key={index}
          cx={node.x}
          cy={node.y}
          r="3"
          fill="rgb(240, 240, 240)" // Very light gray for nodes
        />
      ))}
    </svg>
  );
};

export default NeuralNetworkRenderer;