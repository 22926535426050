import React from 'react';
import ChatComponent from './ChatComponent';
import NeuralNetworkAnimation from './Animations/NeuralNetworkAnimation';

function App() {
  return (
    <div className="App h-screen relative bg-gray-900"> {/* Added bg-gray-900 for very dark gray background */}
      <NeuralNetworkAnimation />
      <div className="absolute bottom-4 left-4 right-4 max-w-2xl mx-auto">
        <ChatComponent />
      </div>
    </div>
  );
}

export default App;