import { useState, useEffect, useRef } from 'react';

const MAX_SPEED = 10; // pixels per second
const NODE_COUNT = 150; // Doubled from 100 to 200

const generateGaussian = (mean, stdDev) => {
  const u1 = Math.random();
  const u2 = Math.random();
  const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2);
  return z0 * stdDev + mean;
};

export const useNeuralNetworkLogic = (dimensions) => {
  const [nodes, setNodes] = useState([]);
  const [connections, setConnections] = useState([]);
  const animationRef = useRef(null);
  const lastUpdateTimeRef = useRef(Date.now());

  useEffect(() => {
    if (dimensions.width && dimensions.height) {
      const centerX = dimensions.width / 2;
      const centerY = dimensions.height / 2;
      const radiusX = dimensions.width * 0.45; // Slightly increased to spread nodes more
      const radiusY = dimensions.height * 0.45; // Slightly increased to spread nodes more

      const newNodes = Array.from({ length: NODE_COUNT }, () => {
        // Use Gaussian distribution for both angle and radius
        const angle = generateGaussian(0, 1) * 2 * Math.PI;
        const radius = Math.abs(generateGaussian(0.5, 0.2)); // Using abs to ensure positive values

        // Calculate x and y positions using a modified polar coordinate system
        const x = centerX + radiusX * radius * Math.cos(angle);
        const y = centerY + radiusY * radius * Math.sin(angle);

        const speed = Math.random() * MAX_SPEED / 1000;
        const moveAngle = Math.random() * 2 * Math.PI;

        return {
          x: Math.max(0, Math.min(x, dimensions.width)),
          y: Math.max(0, Math.min(y, dimensions.height)),
          vx: Math.cos(moveAngle) * speed,
          vy: Math.sin(moveAngle) * speed,
        };
      });
      setNodes(newNodes);

      const newConnections = [];
      for (let i = 0; i < newNodes.length; i++) {
        for (let j = i + 1; j < newNodes.length; j++) {
          if (Math.random() < 0.025) { // Reduced connection probability to maintain similar density
            newConnections.push({ from: i, to: j });
          }
        }
      }
      setConnections(newConnections);
    }
  }, [dimensions]);

  useEffect(() => {
    const animate = () => {
      const now = Date.now();
      const deltaTime = now - lastUpdateTimeRef.current;
      lastUpdateTimeRef.current = now;

      setNodes(prevNodes => prevNodes.map(node => {
        let newX = node.x + node.vx * deltaTime;
        let newY = node.y + node.vy * deltaTime;
        let newVx = node.vx;
        let newVy = node.vy;

        if (newX < 0 || newX > dimensions.width) {
          newVx = -newVx;
          newX = newX < 0 ? 0 : dimensions.width;
        }
        if (newY < 0 || newY > dimensions.height) {
          newVy = -newVy;
          newY = newY < 0 ? 0 : dimensions.height;
        }

        return {
          ...node,
          x: newX,
          y: newY,
          vx: newVx,
          vy: newVy,
        };
      }));

      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationRef.current);
  }, [dimensions]);

  return { nodes, connections };
};
