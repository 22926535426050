import React, { useState } from 'react';

const ChatComponent = () => {
  const [input, setInput] = useState('');

  return (
    <div className="p-4 flex items-center space-x-4">
      <input
        type="text"
        className="flex-grow p-3 bg-gray-200 bg-opacity-70 rounded-full border-none focus:outline-none focus:ring-2 focus:ring-blue-300 placeholder-gray-500"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="The Dmytro AI chat will be available soon..."
        disabled
      />
      <button
        className="p-3 bg-gray-400 bg-opacity-70 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:opacity-50"
        disabled
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 transform rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
        </svg>
      </button>
    </div>
  );
};

export default ChatComponent;