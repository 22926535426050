import React, { useState, useEffect, useRef } from 'react';
import { useNeuralNetworkLogic } from './NeuralNetworkLogic';
import NeuralNetworkRenderer from './NeuralNetworkRenderer';

const NeuralNetworkAnimation = () => {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const { nodes, connections } = useNeuralNetworkLogic(dimensions);

  return (
    <div ref={containerRef} className="w-full h-full">
      <NeuralNetworkRenderer
        nodes={nodes}
        connections={connections}
        dimensions={dimensions}
      />
    </div>
  );
};

export default NeuralNetworkAnimation;
